import React from 'react';
import './Education.scss';
import tamulogo from './tamulogo.png'


function Education(props) {
  return (
    <div className="education">
        <div className="edu-txt">
        <div className="education-title">Education</div>
        <div className="education-body">I am a second-year student Texas A&M University in the Engineering Honors program. I am majoring in Aerospace Engineering and am also getting a minor in Computer Science. I plan on graduating May 2022! </div>
        </div>
        <div className="education-img-holder">
            <img className="edu-img" src={tamulogo}/>
        </div>
        
    </div>
  );
}

export default Education;
