import React from 'react';
import './Info.scss';
import { SocialIcon } from 'react-social-icons';
import resume from './Malachi Mayfield Resume (10.29.19).pdf'


function Info(props) {
  return (
    <div className="info">
        <div className="info-title">Contact</div>
        <div className="info-body">Want to get in contact? Have any questions for me? Send me an email, I'd love to hear from you! <br/>Connect with me on LinkedIn or take a look at my resume or find some more information about my projects on GitHub.</div>
        <a className="resume" href={resume}>My Resume</a>
        <div className="icons">
            <SocialIcon className="soc-icon" url="https://www.linkedin.com/in/malachi-mayfield-47b22b90/"/>
            <SocialIcon className="soc-icon" url="https://github.com/malachimayfield"/>
            <SocialIcon className="soc-icon" network="email" url="mailto:malachimayfield@gmail.com"/>
        </div>
    </div>
  );
}

export default Info;
