import React from 'react';
import './Container.scss';


function Container(props) {
  return (
    <div className="container-title">
      {props.name}
    <div className="container" ref={props.ref}>
        
        {props.children}
    </div>
    </div>
  );
}

export default Container;
