import React from 'react';
import './Header.scss';
import headshot from './headshot1.jpg';
import headshot2 from './headshot1-removebg-preview.png';
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'



class Header extends React.Component{

  constructor(props) {
    super(props);
  }

  handleClick= (location) => {
    switch(location){
      case 'pe':
          window.scrollTo({top:this.props.peRef(), behavior:'smooth'});
          break;
      case 'info':
          window.scrollTo({top:this.props.infoRef(), behavior:'smooth'});
          break;
      case 'more':
          window.scrollTo({top:this.props.moreRef(), behavior:'smooth'});
    }
    
  }


  render(){
  return (
      <div className="header">
        <div className="top-bar">
            <div className="name">
                Malachi Mayfield
            </div>
            
        </div>
        <div className="navbar">
           

            <a className="link" onClick={() => this.handleClick('pe')} >Projects</a>
            <a className="link" onClick={() => this.handleClick('info')}>Information</a>
            <a className="link" onClick={() => this.handleClick('more')}>More</a>

        </div>
    </div>
  );
}}

export default Header;
