import React from 'react';
import './App.scss';
import Header from './Header';
import Container from './Container'
import Info from './Info';
import Project from './Project';
import Home from './Home';
import { ScrollTo } from "react-scroll-to"
import spacecraft from './spacecraft.jpg';
import idtech from './idtech.jpg';
import earth from './2015-10-31.gif'
import Education from './Education';
import dronetop from './drone-top.jpg';
import droneclose from './drone-close.jpg';
import dronevid from './drone-vid.gif';
import dronetoptn from './drone-top-tn.jpg';
import droneclosetn from './drone-close-tn.jpg';
import dronevidtn from './drone-vid-tn.gif';
import { Link, animateScroll as scroll } from "react-scroll";
import { Icon } from '@blueprintjs/core';

const droneImages = [
      {
        original: droneclose,
        thumbnail: droneclosetn,
      },
      {
        original: dronevid,
        thumbnail: dronevidtn,
      },
      {
        original: dronetop,
        thumbnail: dronetoptn,
      },
];

let peOffset = 0;
let infoOffset = 0;
let moreOffset = 0;



class App extends React.Component {

  constructor(props) {
    super(props);
    this.pe = React.createRef();
    this.info = React.createRef();
    this.more = React.createRef();

    this.location = 0;

    this.state = {
      upArrow: false
    }

    
  }

  componentDidMount(){
    window.addEventListener('scroll', this.addArrow);
  }

  addArrow = () => {
    if(window.pageYOffset > window.innerHeight) {
      this.setState({
        upArrow: true
      });

    } else {
      this.setState({
        upArrow: false
      });
    }


  }


  clickHandlePe = () => {
    peOffset = this.pe.current.offsetTop;
    console.log(peOffset);
    return peOffset;
  }
  clickHandleInfo = () => {
    infoOffset = this.info.current.offsetTop;
    console.log(infoOffset);
    return infoOffset;
  }

  clickHandleMore = () => {
    moreOffset = this.more.current.offsetTop;
    console.log(moreOffset);
    return moreOffset;
  }


  
render(){
  let upArr = null;

  if(this.state.upArrow){
    console.log('in');
    upArr = <div>hello</div>
  }

  console.log(this.state.upArrow);

  
  return (
    <div>
    <div className="App" id="App">

    
        <Header peRef={this.clickHandlePe} moreRef={this.clickHandleMore} infoRef={this.clickHandleInfo}/>
        <Home/> 


        

        <div ref={this.pe}>
          <Container name="Projects and Experience" >
            <Project 
              title="Arduino Flight Controller"
              type="gallery" 
              body="Using an Arduino Uno, I developed a basic flight controller capable of flight. I implemented nearly all parts of the controller, such as reading RC input from a reciever, reading gyro input from an IMU, control algorithms, and outputing signals to the electronic speed controllers. Below are images of the quadcopter and a short video of one of the first (semi-stable) flights." 
              images={droneImages}
            />
          
            <Project 
              title="SpaceCRAFT Exploration Challenge - First Place Winner" 
              body="The SpaceCRAFT Exploration Challenge was a two day, hackathon style event held at South by Southwest 2019. The goal of the challege was to program an autounomous path planning algorithm to direct a rover between waypoints on a far away planet. The terrain was scattered with obstacles and different surfaced making traversing the planet very difficult. My 3 teammates and I created an algorithm that was able to get the rover closest in the allotted time, giving us a first place win!" 
              link="https://engineering.tamu.edu/news/2019/04/spacecraft-offers-up-virtual-reality-challenge-at-south-by-southwest-2019.html"
              linktitle="SpaceCRAFT offers up virtual reality challenge"
              image={spacecraft}
            />
            <Project 
              title="iD Tech Instructor" 
              body="During Summer 2019 I worked as an instructor at iD Tech camps, where I introduced kids to programming, engineering, and other STEM concepts. I taught a variety of courses in several languages over the weeks and really sharpened my debugging skills. I learned first hand that the best way to know how well you understand a concept is by your ability to teach it to others." 
              image={idtech}
            />
          </Container>
        </div>
        <div ref={this.info}>
          <Container  name="Information">
            <Info/>
            <Education/>
          </Container>
        </div>
        <div ref={this.more}>
          <Container name="More" >
            <Project 
              title="NASA API project" 
              body="This was a short little project that I did in Python. I pulled satallite images from a NASA API and used them to make some short GIFS. When I learned how to access the vast variety of data I could find I made some interesting GIFS of the Earth rotating, ice melting over time, and deforestation in South America." 
              image={earth}
            />
            
          </Container>
        </div>
        
       
        
        
      

    </div>
           {this.state.upArrow ? 

            <Icon className="uparrow" icon="double-chevron-up" iconSize={50} color="white" onClick={() => window.scrollTo({top:0, behavior:'smooth'})}/> :
            <div />
            }
    </div>
  );
}
}

export default App;
