import React from 'react';
import './Project.scss';
import ImageGallery from 'react-image-gallery';

function Project(props) {
  let imagetype;
  let link;
  if (props.type == 'gallery') {
    imagetype =  <ImageGallery items={props.images} />;
  } else {
    imagetype = <div className="proj-img-holder"><img className="project-image" src={props.image}></img></div>;
  }

  if(props.link != null){
    link = <a href={props.link} className="project-link">{props.linktitle}</a>;
  }

  return (
    <div className="Project">
        <div className="project">
            <div className="title">{props.title}</div>
            <div className="body">{props.body}</div>
            {link}
            {imagetype}
        
            {/* <div className="proj-img-holder"><img className="project-image" src={props.image}></img></div> */}
        </div>
    </div>
  );
}

export default Project;
