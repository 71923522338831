import React from 'react';
import './Home.scss';
import headshot from './headshot1.jpg';
import headshot2 from './headshot1-removebg-preview.png';

function Home() {
  return (
    <div className="home">
        <div className="home-text">
            
            <div>Welcome to my website!</div>
            <br/>
            <div>My name is Malachi and I'm a student at Texas A&M studying Aerospace Engineering. I love to take on challenges that involve writing code and building things. This website serves as a place to share my projects and experiences, take a look around!</div>
        </div>
        <div className="profile-img" >
            <img className="headshot" src={headshot}/>
        </div>
    </div>
  );
}

export default Home;
